import ApartmentGallery from '../frontend/apartment_gallery';
import ApartmentCarousel from '../frontend/apartment_carousel';
import ApartmentCarouselThumbnails from '../frontend/apartment_carousel_thumbnails';

const Frontend = {
  ApartmentCarousel,
  ApartmentGallery,
  ApartmentCarouselThumbnails,
};

window.Frontend = Frontend;
