import Player from '@vimeo/player'

const SELECTORS = {
  CAROUSEL: '#apartment-carousel',
  NEXT_CONTROL: '.carousel-control-next',
  PREV_CONTROL: '.carousel-control-prev',
  ITEM: '.carousel-item',
  VIDEO_ITEM: '.carousel-item[data-video]',
}

const CLASSES = {
  HIDDEN: 'd-none',
}

const SWIPE_THRESHOLD = 50

class ApartmentCarousel {
  constructor(selector) {
    this.selector = selector || SELECTORS.CAROUSEL

    this.firstInit = true
  }

  init() {
    if (!this.firstInit) return

    this.initElements()
    this.initEvents()

    this.firstInit = false
  }

  initElements() {
    this.$carousel = $(this.selector)
    this.$nextControl = this.$carousel.find(SELECTORS.NEXT_CONTROL)
    this.$prevControl = this.$carousel.find(SELECTORS.PREV_CONTROL)
    this.$videoItems = this.$carousel.find(SELECTORS.VIDEO_ITEM)
    this.$items = this.$carousel.find(SELECTORS.ITEM)
  }

  initEvents() {
    this.$nextControl.on('click', this.handleNextControlClick.bind(this))
    this.$prevControl.on('click', this.handlePrevControlClick.bind(this))
    this.$videoItems.find('img').on('click', this.handleVideoItemPosterClick.bind(this))
    this.$videoItems.find('.video__play-button').on('click', this.handleVideoItemPosterClick.bind(this))
    this.$carousel.on('slide.bs.carousel', this.handleCarouselSlide.bind(this))
    this.$items.on('touchstart', this.handleCarouselTouchstart.bind(this))
    this.$items.on('touchend', this.handleCarouselTouchend.bind(this))
  }

  handleNextControlClick() {
  }

  handlePrevControlClick() {
  }

  handleCarouselSlide() {
    this.stopPlayingVideos()
    this.restoreAllVideoItemsPoster()
  }

  handleVideoItemPosterClick(e) {
    e.stopPropagation()
    this.loadVideoIFrame({ videoItem: $(e.currentTarget).closest(SELECTORS.VIDEO_ITEM) })
    $('#carousel-caption').hide()
    $('.hero-button').hide()
    this.startPlayingVideos()
  }

  handleCarouselTouchstart(e) {
    this.touchStartX = e.originalEvent.touches[0].clientX
  }

  handleCarouselTouchend(e) {
    const touchEndX = e.originalEvent.changedTouches[0].clientX
    const deltaX = touchEndX - this.touchStartX
    if (deltaX > SWIPE_THRESHOLD) {
      this.$carousel.carousel('prev')
    } else if (deltaX < -SWIPE_THRESHOLD) {
      this.$carousel.carousel('next')
    }
    this.touchStartX = undefined
  }


  loadVideoIFrame({ videoItem }) {
    const $videoItem = $(videoItem)
    const iframeSrc = $videoItem.attr('data-iframe-src')
    if ($videoItem.find('iframe').attr('src') != iframeSrc) {
      $videoItem.find('iframe').attr('src', iframeSrc)
    }

    $videoItem.find('.embed-responsive').removeClass(CLASSES.HIDDEN)
    $videoItem.find('img').addClass(CLASSES.HIDDEN)
    this.isVideoLoaded = true
  }

  restoreVideoItemPoster({ videoItem }) {
    const $videoItem = $(videoItem)
    $videoItem.find('.embed-responsive').addClass(CLASSES.HIDDEN)
    $videoItem.find('img').removeClass(CLASSES.HIDDEN)
  }

  restoreAllVideoItemsPoster() {
    this.$videoItems.each((index, el) => {
      this.restoreVideoItemPoster({ videoItem: el })
      $('#carousel-caption').show()
      $('.hero-button').show()
    })
  }

  startPlayingVideos() {
    this.$videoItems.find('iframe').each((index, el) => {
      if (!this.isVideoLoaded) { return }
      this.player = this.player || new Player(el)
      this.player.play()
    })
  }

  stopPlayingVideos() {
    this.$videoItems.find('iframe').each((index, el) => {
      if (!this.isVideoLoaded) { return }
      // TODO: find out how to stop vimeo video without player library
      // TODO: (avoiding src attribute swap)
      this.player = this.player || new Player(el)
      this.player.pause()
    })
  }
}

export default ApartmentCarousel
