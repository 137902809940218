import 'lightgallery.js'
import 'lg-video.js'
import Player from '@vimeo/player'


const SELECTORS = {
  CAROUSEL: '#apartment-carousel',
  LIGHT_GALLERY: '.lg-outer',
  ITEM: '.carousel-item',
}

class ApartmentGallery {
  constructor(selector) {
    this.selector = selector || SELECTORS.CAROUSEL

    this.firstInit = true
  }

  init() {
    if (!this.firstInit) return

    this.initElements()
    this.initGallery()
    this.initEvents()

    this.firstInit = false
  }

  initElements() {
    this.$carousel = $(this.selector)
  }

  initGallery() {
    window.lightGallery(this.$carousel.get(0), {
      selector: SELECTORS.ITEM,
      mode: 'lg-fade',
      addClass: 'apartment-gallery',
      download: false,
      vimeoPlayerParams: {
        title: 0,
        byline: 0,
        portrait: 0,
        color: 'aaaaaa',
        loop: 0,
        autoplay: 0,
      },
    })
  }

  initEvents() {
    this.$carousel.on('onBeforeSlide.lg', this.handleBeforeSlide.bind(this))
    this.$carousel.on('onAfterSlide.lg', this.handleAfterSlide.bind(this))
  }

  handleAfterSlide() {
    this.$lightGallery = $(SELECTORS.LIGHT_GALLERY)
    this.stopPlayingVideos()
  }

  handleBeforeSlide() {
  }

  stopPlayingVideos() {
    this.$lightGallery.find('iframe').each((index, el) => {
      // TODO: find out how to stop vimeo video without player library
      this.player = this.player || new Player(el)
      this.player.pause()
    })
  }

}

export default ApartmentGallery
