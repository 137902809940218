import ResizeObserver from 'resize-observer-polyfill';

const SELECTORS = {
  CAROUSEL: '#apartment-carousel',
  THUMBNAILS: '#apartment-carousel .thumbnails',
  INDICATORS_WRAPPER: '.indicators-wrapper',
  INDICATOR: '.carousel-indicators',
  LIST_INLINE: '.list-inline',
  ITEM: '.list-inline-item',
  NEXT_CONTROL: '.indicators-control-next',
  PREV_CONTROL: '.indicators-control-prev',
  NTH_ITEM: (n) => `#apartment-carousel .thumbnails .list-inline-item:nth-child(${n})`,
};

const CLASSES = {
  INDICATORS_CONTROL_DISABLED: 'indicators-control--disabled',
};

const DEFAULTS = {
  SWIPE_THRESHOLD: 50,
  VISIBLE_ITEMS_COUNT_RESPONSIVE: [
    // MOBILE
    {
      minWidth: 0,
      maxWidth: 563,
      count: 3,
    },
    // TABLET
    {
      minWidth: 564,
      maxWidth: 767,
      count: 4,
    },
    // DESKTOP
    {
      minWidth: 768,
      maxWidth: Number.POSITIVE_INFINITY,
      count: 5,
    },
  ],
};

class ApartmentCarouselThumbnails {
  constructor({ selector, visibleItemsCountResponsive, itemsToScrollCount = 'auto' } = {}) {
    this.selector = selector || SELECTORS.THUMBNAILS;

    this.counter = 1;
    this.translateAmount = 0;
    this.itemsToScrollForcedCount = itemsToScrollCount;
    this.visibleItemsCountResponsive = visibleItemsCountResponsive;

    this.firstInit = true;
  }

  init() {
    if (!this.firstInit) return;

    this.initElements();
    this.initEvents();

    this.setItemsHeightCssVariable(this.visibleItemsCount || DEFAULTS.VISIBLE_ITEMS_COUNT_RESPONSIVE);

    this.firstInit = false;
  }

  initElements() {
    this.$thumbnails = $(this.selector);
    this.$carousel = $(SELECTORS.CAROUSEL);
    this.$indicatorsWrapper = this.$thumbnails.find(SELECTORS.INDICATORS_WRAPPER);
    this.indicatorsWrapperEl = this.$indicatorsWrapper.get()[0];
    this.$indicators = this.$thumbnails.find(SELECTORS.INDICATOR);
    this.$firstItem = this.$thumbnails.find('.list-inline-item:first-child');
    this.$lastItem = this.$thumbnails.find('.list-inline-item:last-child');
    this.$list = this.$thumbnails.find(SELECTORS.LIST_INLINE);
    this.$items = this.$thumbnails.find(SELECTORS.ITEM);
    this.$nextControl = this.$thumbnails.find(SELECTORS.NEXT_CONTROL);
    this.$prevControl = this.$thumbnails.find(SELECTORS.PREV_CONTROL);
  }


  initEvents() {
    this.$nextControl.on('click', this.handleNextControlClick.bind(this));
    this.$prevControl.on('click', this.handlePrevControlClick.bind(this));
    this.$carousel.on('slide.bs.carousel', this.handleCarouselSlide.bind(this));
    this.$indicatorsWrapper.on('touchstart', this.handleThumbnailCarouselTouchstart.bind(this));
    this.$indicatorsWrapper.on('touchend', this.handleThumbnailCarouselTouchend.bind(this));

    const resizeObserver = new ResizeObserver(element => {
      this.setItemsHeightCssVariable(this.visibleItemsCount || DEFAULTS.VISIBLE_ITEMS_COUNT_RESPONSIVE);
    });

    resizeObserver.observe(this.$carousel.get(0));
  }

  handleNextControlClick() {
    this.slideNext();
  }

  handlePrevControlClick() {
    this.slidePrev();
  }

  handleCarouselSlide(e) {
    const itemTargetNumber = e.to % this.$items.length;

    if (this.isItemOutOfVisibleWindow({ itemNumber: itemTargetNumber })) { this.slideTo(itemTargetNumber); }
  }

  handleThumbnailCarouselTouchstart(e) {
    this.touchStartX = e.originalEvent.touches[0].clientX;
  }

  handleThumbnailCarouselTouchend(e) {
    const touchEndX = e.originalEvent.changedTouches[0].clientX;
    const deltaX = touchEndX - this.touchStartX;
    if (deltaX > DEFAULTS.SWIPE_THRESHOLD) {
      this.slidePrev();
    } else if (deltaX < -DEFAULTS.SWIPE_THRESHOLD) {
      this.slideNext();
    }
    this.touchStartX = undefined;
  }

  slideNext() {
    this.slideTo(this.currentItemNumber() + this.itemsToScrollCount());
  }

  slidePrev() {
    this.slideTo(this.currentItemNumber() - this.itemsToScrollCount());
  }

  isItemOutOfVisibleWindow({ itemNumber }) {
    const nthItemEl = document.querySelector(SELECTORS.NTH_ITEM(itemNumber + 1));
    const wrapperEl = this.$indicatorsWrapper.get(0);
    return (
      nthItemEl.getBoundingClientRect().right > wrapperEl.getBoundingClientRect().right ||
      $(nthItemEl).offset().left < this.$indicatorsWrapper.offset().left
    );
  }

  currentItemNumber() {
    return Math.ceil(this.translateAmount / this.itemsDistance());
  }

  slideTo(itemNumber) {
    this.translateAmount = this.itemsDistance() * (itemNumber);
    this.translateAmount = Math.min(Math.max(this.translateAmount, 0), this.maxTranslate());
    this.$indicators.css('transform', `translateX(${-this.translateAmount}px)`);
    this.disableControls();
  }

  itemsDistance() {
    const $secondItem = this.$firstItem.next();
    return Math.abs($secondItem.offset().left - this.$firstItem.offset().left);
  }

  itemsToScrollCount() {
    if (Number.isInteger(this.itemsToScrollForcedCount)) { return this.itemsToScrollForcedCount; }

    // count how many items are needed for the last completely visibile to still be visible after scrolling
    return Math.min(Math.max(1, Math.floor(this.$indicatorsWrapper.outerWidth() / this.itemsDistance())), 3);
  }

  maxTranslate() {
    // max translate value for which the last item is right aligned with the container
    return this.$indicators.get(0).scrollWidth - this.$indicatorsWrapper.outerWidth();
  }

  disableControls() {
    this.$prevControl.removeClass(CLASSES.INDICATORS_CONTROL_DISABLED);
    this.$nextControl.removeClass(CLASSES.INDICATORS_CONTROL_DISABLED);

    if (this.translateAmount <= 0) {
      this.$prevControl.addClass(CLASSES.INDICATORS_CONTROL_DISABLED);
    } else if (this.translateAmount >= this.maxTranslate()) {
      this.$nextControl.addClass(CLASSES.INDICATORS_CONTROL_DISABLED);
    }
  }

  setItemsHeightCssVariable(visibleItemsCountResponsive) {
    const carousel = document.querySelector(SELECTORS.CAROUSEL);
    const listWidth = carousel.offsetWidth;
    const visibleItemsCount = visibleItemsCountResponsive.find(e => listWidth >= e.minWidth && listWidth <= e.maxWidth).count;
    const itemsGap = getComputedStyle(carousel).getPropertyValue('--apartment-carousel-thumbnail-items-gap');
    const totalGap = parseFloat(itemsGap) * (visibleItemsCount - 1);
    const availableWidth = listWidth - totalGap;
    const height = availableWidth * 9 / 16 / visibleItemsCount;
    carousel.style.setProperty('--apartment-carousel-thumbnail-height', `${height}px`);
  }
}

export default ApartmentCarouselThumbnails;
